@import url('https://fonts.googleapis.com/css?family=Roboto:400,400i,700');

html {
    height: 100%;
}

body {
    background-color: #e8eaec;
    position: relative;
    min-height: 100%;
    // font-family: 'Roboto', sans-serif;

    &.is-authenticated {
        background-color: #fff;
    }
}

@keyframes fade-up {

    from {
        opacity: 0;
        visibility: hidden;
        transform: translateY(100px);
    }

    to {
        opacity: 1;
        visibility: visible;
        transform: translateY(0px);
    }

}

.c-login-form {
    animation: fade-up 1s;
}

.c-login {
    padding: 100px 18px;
    max-width: 496px;
    margin-left: auto;
    margin-right: auto;

    h1 {
        text-transform: uppercase;
        font-weight: 700;
        color: #1f2532;
    }

    .form-control {
        height: 60px;
        border-radius: 0;
        font-size: 18px;
        border: 1px solid #ddd;
        box-shadow: 0 2px 2px rgba(0,0,0,0.10);
        padding-left: 18px;
        padding-right: 18px;
    }

    .form-group {
        margin-bottom: 36px;

        a {
            float: right;
        }

    }

    .btn-primary {
        height: 60px;
        border-radius: 30px;
        background-color: #f63f41;
        border-color: #f63f41;
        width: 50%;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 18px;

        &:hover,
        &:focus,
        &:active {
            background-color: #f63f41;
            border-color: #f63f41;
        }

        &:focus {

        }

    }

    label,
    .checkbox label {
        cursor: pointer;
        font-size: 18px;
        font-weight: 700;
    }

    .link {
        font-weight: 700;
        font-size: 18px;
        color: #f63f41;
    }

    footer {
        padding: 2rem;
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
    }

}

.c-login__header {
    margin-bottom: 36px;
}
